import "./FloorPlate-v2.scss";

const DEBUG_VERBOSE = false;
const CLASS_NAME = "[FloorPlateBlockV2]";
const TAG_NAME = "chunkwc-floor-plate-v2";

const BEDROOMS: any = [{

  'studio': [
    'residence_09_hover',
    'residence_07_hover',
  ],

  '1bed': [
    'residence_13_hover',
    'residence_11_hover',
    'residence_03_hover',
    'residence_05_hover',
  ],
  '2bed': [
    'residence_01_hover',
    'residence_04_hover',
    'residence_06_hover',
    'residence_08_hover',
  ],
  '3bed': [
    'residence_00_hover',
    'residence_02_hover',
  ]

},
{

  'studio': [
    'residence_46_hover',
    'residence_42_hover',
    'residence_40_hover',
    'residence_38_hover',
    'residence_36_hover',
    'residence_34_hover',
    'residence_32_hover',
    'residence_30_hover',
    'residence_28_hover',
    'residence_26_hover',
    'residence_24_hover',
    'residence_22_hover',
    'residence_20_hover',
    'residence_18_hover',
    'residence_16_hover',
    'residence_14_hover',
  ],

  '1bed': [
    'residence_10_hover',
  ],
  '2bed': [
    'residence_44_hover',
    'residence_12_hover',
  ],

}];



// ////////////////////////////////////////////////////////////////////

export default class FloorPlateBlockV2 extends HTMLElement {
  svg: SVGSVGElement;
  tabs: HTMLElement[];
  modal: HTMLElement;
  modalContent: HTMLElement;
  modalCloseBtn: HTMLElement;

  languageCode: string;
  currentTab: number = 0;


  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.init();
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {}

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback() {}

  init() {

    // Get the language details (this is written into the component via Weglot)
    // this.dataset.language = 'ja';
    this.languageCode = this.dataset.language;

    this.initDOMElements();

    this.addTabListeners();

    this.addLegendListeners();

    this.addResidenceListeners();

  }

  destroy() {
    DEBUG_VERBOSE && console.log("destroy");
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  initDOMElements() {

    this.svg = this.querySelector("svg") as SVGSVGElement;
    this.tabs = Array.from(this.querySelectorAll('[data-tab]')) as HTMLElement[];

  }

  addTabListeners() {
    const buttons = Array.from(document.querySelectorAll('.chunkwc__floor-plate-v2__tabs button'));
    buttons.forEach((btn) => {
      btn.addEventListener('click', this.onTabClick, false);
    });
  }

  onTabClick = async () => {

    this.removeSVGListeners();

    const target = event.target as HTMLElement;

    this.currentTab = parseInt(target.dataset.tab);

    await this.updateSVG(target.dataset.imageMobile, target.dataset.imageDesktop);  

    this.tabs.forEach((tab: any) => {
      tab.classList.remove('is-active');  
    })

    target.classList.add('is-active');

    this.addSVGListeners();

  };

  addSVGListeners() {

    this.addLegendListeners();
    this.addResidenceListeners();

  }

  removeSVGListeners() {

    this.removeLegendListeners();
    this.removeResidenceListeners();  

  }

  addLegendListeners() {
    const keys = Array.from(document.querySelectorAll('#item_01_labels, #item_02_labels, #item_03_labels, #item_04_labels'));
    keys.forEach((keyItem) => {

      keyItem.addEventListener('mouseenter', this.onLegendMouseEnter, false);
      keyItem.addEventListener('mouseleave', this.onLegendMouseLeave, false);

    })  
  }

  onLegendMouseEnter = (event: any) => {    

    const target = event.target as HTMLElement;
    switch (target.id) {
      case "item_01_labels":  
        this.highlightResidences("studio");
        break;  
      case "item_02_labels":  
        this.highlightResidences("1bed");
        break;  
      case "item_03_labels":  
        this.highlightResidences("2bed");
        break;  
      case "item_04_labels":  
        this.highlightResidences("3bed");
      break;  
    }

  }

  onLegendMouseLeave = () => {  
    this.unhighlightResidences();
  };


  removeLegendListeners() {

    const keys = Array.from(document.querySelectorAll('#item_01_labels, #item_02_labels, #item_03_labels, #item_04_labels'));
    keys.forEach((keyItem) => {
      keyItem.removeEventListener('mouseenter', this.onLegendMouseEnter);
      keyItem.removeEventListener('mouseleave', this.onLegendMouseLeave);
    })  

  }

  onResidenceMouseEnter = (event: any) => { 

        // Fade up the hover
        const target = event.target as HTMLElement;
        target.style.opacity = '1';
      
        // Fade up the CTA text
        const ctaSelector = `.hover_cta_text.${this.languageCode}`;
        const ctaText = target.querySelector(ctaSelector) as HTMLElement;
        if (ctaText) ctaText.style.opacity = '1';  

  };

  onResidenceMouseLeave = (event: any) => { 

    // Fade out the hover
    const target = event.target as HTMLElement;
    target.style.opacity = '0';

    // Fade out the CTA text
    const ctaSelector = `.hover_cta_text.${this.languageCode}`;
    const ctaText = target.querySelector(ctaSelector) as HTMLElement;
    if (ctaText) ctaText.style.opacity = '0';  

  }

  onLabelMouseEnter = async (event: any) => {   
    const target = event.target as HTMLElement;
    const nearestElement = target.closest('g');
    if (nearestElement) {

      const residenceNumber = this.getResidenceNumber(nearestElement.id);

      const hover = this.querySelector(`#residence_${residenceNumber}_hover`) as HTMLElement;      
      if (hover) { 

        hover.style.opacity = '1';

        const ctaText = hover.querySelector(`.hover_cta_text.${this.languageCode}`) as HTMLElement; 
        if (ctaText) ctaText.style.opacity = '1';
      }

    }
  }

  onLabelMouseLeave = async () => {
    const target = event.target as HTMLElement;
    const nearestElement = target.closest('g');
    if (nearestElement) {

      const residenceNumber = this.getResidenceNumber(nearestElement.id);

      const hover = this.querySelector(`#residence_${residenceNumber}_hover`) as HTMLElement;      
      if (hover) { 

        hover.style.opacity = '0';

        const ctaText = hover.querySelector(`.hover_cta_text.${this.languageCode}`) as HTMLElement; 
        if (ctaText) ctaText.style.opacity = '0';
      }

    }
  }

  onLabelClick = async (event: any) => {

    const target = event.target as HTMLElement;
    const nearestElement = target.closest('g');

    if (nearestElement) {

      if (document.body.classList.contains("modal-loading")){
        return;
      }else{
        document.body.classList.toggle("modal-loading", true);
      }
        
      const residenceNumber = this.getResidenceNumber(nearestElement.id);

      const response = await fetch(
        `${this.dataset.language == 'en' ? '' : '/' + this.dataset.language}/residence/residence-${residenceNumber}/`
      );
      if (response.ok) {
        this.createModal(await response.text());
      } else {
        this.createErrorModal();
      } 
    }

  }

  onResidenceClick = async (event: any) => {  

        if (document.body.classList.contains("modal-loading")){
          return;
        }else{
          document.body.classList.toggle("modal-loading", true);
        }

        // Fade up the hover
        const target = event.target as HTMLElement;

        const residenceNumber = this.getResidenceNumber(target.id);

        const response = await fetch(
          `${this.dataset.language == 'en' ? '' : '/' + this.dataset.language}/residence/residence-${residenceNumber}/`
        );
        if (response.ok) {
          this.createModal(await response.text());
        } else {
          this.createErrorModal();
        }            

  }

  addResidenceListeners() {

    // Add listeners to each label
    const labels = Array.from(document.querySelectorAll(`#${this.languageCode}_labels > *`));  
    labels.forEach((label) => {
      label.addEventListener('click', this.onLabelClick, false);  
      label.addEventListener('touchend', this.onLabelClick, false);  
      label.addEventListener('mouseenter', this.onLabelMouseEnter, false);  
      label.addEventListener('mouseleave', this.onLabelMouseLeave, false);  
    })


    // Add listeners to each residence hover
    const hovers = Array.from(document.querySelectorAll('#Hovers > *'));
    hovers.forEach((hover) => {      
      hover.addEventListener('click', this.onResidenceClick, false);  
      hover.addEventListener('touchend', this.onResidenceClick, false);  
      hover.addEventListener('mouseenter', this.onResidenceMouseEnter, false);
      hover.addEventListener('mouseleave', this.onResidenceMouseLeave, false);
    });

  }

  removeResidenceListeners() {

    // Remove listeners from each residence hover
    const hovers = Array.from(document.querySelectorAll('#Hovers > *'));
    hovers.forEach((hover) => {      
      hover.removeEventListener('click', this.onResidenceClick);  
      hover.removeEventListener('mouseenter', this.onResidenceMouseEnter);
      hover.removeEventListener('mouseleave', this.onResidenceMouseLeave);
    });

  }

  createErrorModal() {

    this.modal = document.createElement("div");
    this.modal.classList.add("floorplate_modal");

    this.modal.innerHTML = `
    <div class="floorplate_modal__content floorplate_modal__content--error">
      <h1>Unable to find modal data</h1>
      <div class="floorplate_modal__disclaimer">
        <span>We've been unable to fetch the data for the selected residence. Please try again.</span>
      </div>
    </div>`;

    const btn = document.createElement("div");
    btn.classList.add("floorplate_modal__close");   
    btn.addEventListener("click", () => {
      this.hideModal();
    });

    this.modal.appendChild(btn);

    document.body.classList.toggle("modal-loading", false);
    document.body.classList.toggle("modal-no-scroll", true);
    document.body.appendChild(this.modal);

}

  createModal(data?: any) {
    this.modal = document.createElement("div");
    this.modal.classList.add("floorplate_modal");
    this.modal.innerHTML = data;

    const btn = document.createElement("div");
    btn.classList.add("floorplate_modal__close");   
    btn.addEventListener("click", () => {
      this.hideModal();
    });

    this.modal.appendChild(btn);

    document.body.appendChild(this.modal);
    document.body.classList.toggle("modal-loading", false);
    document.body.classList.toggle("modal-no-scroll", true);

  }

  hideModal() {
    document.body.classList.toggle("modal-no-scroll", false);
    document.body.removeChild(this.modal);
  }


  highlightResidences = (residenceType:string) => {   

    const bedrooms = BEDROOMS[this.currentTab][residenceType];
  
    const hovers = Array.from(document.querySelectorAll('#Hovers > *'));  
    hovers.forEach((hover) => {
  
      const hoverEl = hover as HTMLElement;

      if (!bedrooms.includes(hoverEl.id)) {  
        const path = hover.querySelector('path') as SVGElement;  
        if (path) {
          hoverEl.dataset.rollover = path.getAttribute('fill');
          path?.setAttribute('fill', '#FFFFFF');  
        }
        hoverEl.style.opacity = '1';
      }
  
    });   
  
  };
  
  unhighlightResidences = () => {   
  
    const residences = Array.from(document.querySelectorAll(`#Hovers > *`)); 
    residences.forEach((residence) => {
  
      const residenceEl = residence as HTMLElement;

      const path = residence.querySelector('path') as SVGElement;  
      if (path && residenceEl.dataset.rollover) { 
        path?.setAttribute('fill', residenceEl.dataset.rollover);  
        residenceEl.style.opacity = '0';

      }

  
    });   
  
  };
  
  getResidenceNumber = (id:string) => {
    
    let matches = id.match(/\d+/);

      let residenceNumber = null; 
      if (matches) {
        residenceNumber = matches[0];
      }
    
      return residenceNumber;
    
  }

  async updateSVG(mobileUrl:string, desktopUrl: string) {
    try {
      const mobileDiv = document.querySelector('.chunkwc__floor-plate-v2__svg__mobile-image');
      let mobileRes = await fetch(mobileUrl);
      if (!mobileRes.ok) {
          throw new Error(`HTTP error! status: ${mobileRes.status}`);
      }

      let mobileSVG = await mobileRes.text();
      mobileDiv.innerHTML = mobileSVG;

      const desktopDiv = document.querySelector('.chunkwc__floor-plate-v2__svg__desktop-image');
      let desktopRes = await fetch(desktopUrl);
      if (!desktopRes.ok) {
          throw new Error(`HTTP error! status: ${desktopRes.status}`);
      }
      let desktopSVG = await desktopRes.text();
      desktopDiv.innerHTML = desktopSVG;

      } catch (error) {
        console.error('Error fetching the SVG:', error);
    }


  }


}

customElements.define(TAG_NAME, FloorPlateBlockV2);
