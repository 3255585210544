import "./Video.scss";

const DEBUG_VERBOSE = false;
const CLASS_NAME = "VideoBlock";
const TAG_NAME = "chunkwc-video";

// @ts-ignore
import Player from "@vimeo/player";
import YouTubePlayer from "youtube-player";

// ////////////////////////////////////////////////////////////////////

enum VideoSource {
  Vimeo,
  YouTube,
}

export default class VideoBlock extends HTMLElement {
  private player: Player | typeof YouTubePlayer;
  private iframe: HTMLIFrameElement | null;
  private overlay: HTMLElement | null;
  private videoProvider: VideoSource;
  private controls: boolean = this.dataset.controls == "1" ? true : false;

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.iframe = this.querySelector("iframe");
    this.overlay = this.querySelector(".chunkwc__video__overlay");

    // Get the provider from the passed data attribute
    this.videoProvider =
      this.dataset.provider == "vimeo"
        ? VideoSource.Vimeo
        : VideoSource.YouTube;

    if (this.videoProvider == VideoSource.Vimeo) {
      this.setupVimeoVideo();
    } else {
      this.setupYouTubeVideo();
    }

    this.overlay.addEventListener("click", async () => {
      if (await this.isPlaying) {
        this.pause();
      } else {
        this.play();
      }
    });

    return;
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {}

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback() {}

  setupVimeoVideo() {
    DEBUG_VERBOSE &&
      console.log(CLASS_NAME, `Setting up Vimeo player for ${this.iframe.src}`);
    this.player = new Player(this.iframe);

    this.player.on("play", () => {
      DEBUG_VERBOSE && console.log(CLASS_NAME, `Video playing`);
      this.toggleOverlay(false);
    });

    this.player.on("ended", () => {
      DEBUG_VERBOSE && console.log(CLASS_NAME, `Video ended`);
      this.toggleOverlay(true);
    });

    this.player.on("pause", () => {
      DEBUG_VERBOSE && console.log(CLASS_NAME, `Video paused`);
      this.toggleOverlay(true);
    });
  }

  setupYouTubeVideo() {
    DEBUG_VERBOSE &&
      console.log(
        CLASS_NAME,
        `Setting up YouTube player for ${this.iframe.src}`
      );

    // this.player = YouTubePlayer(this.iframe);

    this.player = YouTubePlayer(this.iframe);
    this.player.on("stateChange", (event: any) => {
      /*

			YouTube Status Codes

			-1 (unstarted)
			0 (ended)
			1 (playing)
			2 (paused)
			3 (buffering)
			5 (video cued).

			*/

      switch (event.data) {
        case 0:
          DEBUG_VERBOSE && console.log(CLASS_NAME, `Video ended`);
          this.toggleOverlay(true);
          break;
        case 2:
          DEBUG_VERBOSE && console.log(CLASS_NAME, `Video paused`);
          // The YouTube player emits a pause even when seeking, so we add
          // a small timeout here to let the seek complete. If the timeout
          // passes and we're still paused, we can show the overlay
          setTimeout(() => {
            if (event.target.getPlayerState() == 2) this.toggleOverlay(true);
          }, 1000);
          break;
        case 1:
          DEBUG_VERBOSE && console.log(CLASS_NAME, `Video playing`);
          this.toggleOverlay(false);
      }
    });
  }

  public play() {
    if (this.videoProvider == VideoSource.Vimeo) {
      this.player.play();
    } else {
      this.player.playVideo();
    }
  }

  public pause() {
    if (this.videoProvider == VideoSource.Vimeo) {
      this.player.pause();
    } else {
      this.player.pauseVideo();
    }
  }

  toggleOverlay(visible: boolean) {
    if(this.controls && this.overlay.classList.contains("is-hidden")) {
      return;
    } else if (this.controls) {
      this.overlay.classList.toggle("is-hidden", !visible);
    } else {
      this.overlay.style.opacity = visible ? "1" : "0";
    }
  }

  get isPlaying() {
    if (this.videoProvider == VideoSource.Vimeo) {
      return this.player.getPaused().then((paused: boolean) => {
        return !paused;
      });
    } else {
      return this.player.getPlayerState().then((state: number) => {
        return state == 1;
      });
    }
  }
}

customElements.define(TAG_NAME, VideoBlock);
