import "./FloorPlate.scss";

const DEBUG_VERBOSE = false;
const CLASS_NAME = "[FloorPlateBlock]";
const TAG_NAME = "chunkwc-floor-plate";

// ////////////////////////////////////////////////////////////////////

export default class FloorPlateBlock extends HTMLElement {
  svg: SVGSVGElement;
  modal: HTMLElement;
  modalContent: HTMLElement;
  modalCloseBtn: HTMLElement;

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    this.init();
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {}

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback() {}

  init() {
    this.initDOMElements();
    this.addListeners();
  }

  destroy() {
    DEBUG_VERBOSE && console.log("destroy");
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  initDOMElements() {
    this.svg = this.querySelector("svg") as SVGSVGElement;
  }

  addListeners() {
    this.addEventListener("click", (e: any) => {
      let target = e.target;

      this.onFloorplanClick(target);

      // switch between floor plates
      if(target.tagName == "BUTTON") {
        let floorPlates = Array.from(this.querySelectorAll('[data-floorplate]')) as HTMLElement[],
            tabs = Array.from(this.querySelectorAll('[data-tab]')) as HTMLElement[];

        // remove active class from all floor plates and tabs
        floorPlates.forEach((el: any, i) => {
          el.classList.remove('is-active');
          tabs[i].classList.remove('is-active');
        });

        // add active class to clicked tab and corresponding tower plate
        target.classList.add('is-active');
        floorPlates[target.dataset.tab].classList.add('is-active');
      }
    });
  }

  async onFloorplanClick(target: HTMLElement) {
    const residence = target.closest('[id^="Res-"]');
    if (residence) {
      // Find a page with this ID
      const response = await fetch(
        `/wp-json/chunk-wp-blocks/v1/residences/${target.id}`
      );
      if (response.ok) {
        const json = await response.json();
        if (json) this.createModal(json);
      } else {
        this.createModal();
      }
    }
  }

  createModal(data?: any) {
    this.modal = document.createElement("div");
    this.modal.classList.add("floorplate_modal");

    if (!data) {
      this.modal.innerHTML = `
        <div class="floorplate_modal__content floorplate_modal__content--error">
          <div class="container">
            <h1>Unable to find modal data</h1>
            <div class="floorplate_modal__disclaimer">
              <span>We've been unable to fetch the data for the selected residence. Please try again.</span>
            </div>
            <div class="floorplate_modal__close"></div>
          </div>
        </div>`;
    } else {
      this.modal.innerHTML = `
      <div class="floorplate_modal__content" data-id="${data.map_id}">
        <div class="container container--np">
          <div class="floorplate_modal__header">
            <div class="grid">
              <div class="col-span-4 md:col-span-8  lg:col-span-6">
                <div class="floorplate_modal__info">
                  <h2 class="h2">${data.name}</h2>
                  <ul class="list">
                    <li><span id="floorplate_modal__info-bedrooms">${data.bedrooms} Bedrooms</span></li>
                    <li><span id="floorplate_modal__info-bedrooms">${data.bathrooms} Bedrooms</span></li>
                    <li><span id="floorplate_modal__info-size">${data.square_feet} Square Feet</span></li>
                  </ul>
                </div>
              </div>
              <div class="col-span-4 md:col-span-8 lg:col-span-6">
                <chunkwc-aspect-ratio>
                  ${data.featured_image}
                </chunkwc-aspect-ratio>
              </div>
            </div>
          </div>

          <img src="${data.floorplan}" alt="" class="floorplate_modal__desktop-image" />
          <img src="${data.mobile_floorplan}" alt="" class="floorplate_modal__mobile-image" />

          <div class="floorplate_modal__disclaimer">
            <span>${data.disclaimer}</span>
          </div>
          
          <div class="floorplate_modal__close"></div>
        </div>
      </div>`;
    }

    const btn = this.modal.querySelector(".floorplate_modal__close");
    btn.addEventListener("click", () => {
      this.hideModal();
    });

    document.body.classList.toggle("modal-no-scroll", true);
    document.body.appendChild(this.modal);
  }

  hideModal() {
    document.body.classList.toggle("modal-no-scroll", false);
    document.body.removeChild(this.modal);
  }
}

customElements.define(TAG_NAME, FloorPlateBlock);
