import { Logger } from '../../utils/Logger'; 
import { gsap } from 'gsap';

import './Header.scss';

const DEBUG_VERBOSE = false;
const CLASS_NAME = '[HeaderBlock]';
const TAG_NAME = 'chunkwc-header';

// ////////////////////////////////////////////////////////////////////

export default class HeaderBlock extends HTMLElement {
	private _button: HTMLElement;
	private _logo: HTMLElement;
	private _menu: HTMLElement;

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');

		let node = document.createElement('div');
		node.setAttribute("id", "contentstart");
		let target = document.querySelector('.chunkwc__hero');
		if(!target) {
			target = document.querySelector('h1');
		}

		target.after(node);
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {
		Logger.log(`${CLASS_NAME} Component connected`);

		// Bind the component to _el so that we can refer to it
		this._button = this.querySelector('button');
		this._logo = this.querySelector('.chunkwc--header__logo');
		this._menu = this.querySelector('.chunkwc--header__links');

		let onClick = this.onClick.bind(this);

		if (this._button && this._menu) this.addEventListener('click', function (e) {
			onClick(e);	
		});

		this.setGSAPStyles();
		this.removeLanguages();
	}

	setGSAPStyles() {
		if(this._menu) gsap.set(this._menu, { opacity: 0, pointerEvents: "none" })
	}

	onClick(e: MouseEvent) {
		// @ts-ignore
		let target = e.target as HTMLElement,
			activeState = this.querySelector('.is-active') == null ? true : false;

		// smooth scroll to anchored sections
		// @ts-ignore
		if(target.href !== undefined) {

			// @ts-ignore
			if(target.href.includes('#')){
				e.preventDefault();

				// @ts-ignore
				let selectedAnchor = document.querySelector(`#${target.href.split("#")[1]}`),
					shortcutMenuHeight = document.querySelector('chunkwc-shortcut-menu')
				? document.querySelector('chunkwc-shortcut-menu').clientHeight
				: 0;
	
				window.scrollTo({
					left: 0,
					top: selectedAnchor.getBoundingClientRect().top + (document.documentElement.scrollTop - shortcutMenuHeight),
					behavior: 'smooth',
				});
			}
		}

		// moible
		if(window.innerWidth < 1200) {
			if((target.classList.contains('chunkwc--header__logo') && activeState)){

				this.toggleMenu(false);

				return;
			}

			if((target.tagName == 'A')){
				this.toggleMenu(activeState);
			}
	
			if(target.tagName == 'BUTTON'){
				window.scrollTo(0, 0);

				this.toggleMenu(activeState);
			}
		}
	}

	toggleMenu(active: boolean) {
		this._logo.classList.toggle('is-active', active);
		this._button.classList.toggle('is-active', active);
		this._menu.classList.toggle('is-active', active);
		document.querySelector('body').classList.toggle('no-scroll', active);
		document.getElementById('content').classList.toggle('hidden', active);
		document.querySelector('.footer').classList.toggle('hidden', active);

		gsap.to(this._menu, { autoAlpha: active ? 1 : 0, pointerEvents: 'auto'});
	}

	removeLanguages(){
		if(document.querySelector('.menu-item-weglot-480-ko')){
			document.querySelector('.menu-item-weglot-480-ko').remove();
		   document.querySelector('.menu-item-weglot-480-zh').remove();
		}
	}

	showMenu() {
		this._logo.classList.toggle('is-active', true);
		this._button.classList.toggle('is-active', true);
		this._menu.classList.toggle('is-active', true);

		gsap.to(this._menu, { autoAlpha: 1, pointerEvents: 'auto'});
	}

	hideMenu() {
		this._logo.classList.toggle('is-active', false);
		this._button.classList.toggle('is-active', false);
		this._menu.classList.toggle('is-active', false);

		gsap.to(this._menu, { autoAlpha: 0, pointerEvents: 'none'});
	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {}
}

customElements.define(TAG_NAME, HeaderBlock);