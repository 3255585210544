import "./HelloWorld.scss";

const DEBUG_VERBOSE = false;
const CLASS_NAME = "[HelloWorldBlock]";
const TAG_NAME = "chunkwc-hello-world";

// ////////////////////////////////////////////////////////////////////

export default class HelloWorldBlock extends HTMLElement {
  private _el: HelloWorldBlock;

  // ////////////////////////////////////////////////////////////////////
  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
    this._el = this;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    // const title = this._el.querySelector('h1');
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {}

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback() {}
}

customElements.define(TAG_NAME, HelloWorldBlock);
