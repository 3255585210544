import "./Salesforce.scss";

import "country-region-selector";

// @ts-ignore
import * as crs from "country-region-selector";


const HIDE_STATE_FOR_NON_US: boolean = true;

// ////////////////////////////////////////////////////////////////////
declare global {
  interface Window {
    captchaOnLoad: () => void;
    loadCaptcha: any;
  }
}

declare var grecaptcha: any;

const DEBUG_VERBOSE = false;
const CLASS_NAME = "[SalesforceBlock]";
const TAG_NAME = "chunkwc-salesforce";

// ////////////////////////////////////////////////////////////////////

export default class SalesforceBlock extends HTMLElement {
  private form: HTMLFormElement;
  private country: HTMLSelectElement;
  private state: HTMLSelectElement;
  private btn: HTMLButtonElement;
  private checkbox: HTMLButtonElement;
  private checkboxInput: HTMLInputElement;
  private checkboxContainer: HTMLDivElement;

  private endpoint: string;
  private captcha: string;

  private isProduction: boolean = false;
  private hitcount: number = 0;

  constructor() {
    super();
    DEBUG_VERBOSE && console.log(CLASS_NAME, "constructed");
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // Lifecycle Methods
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

  // Invoked each time the custom element is appended into a document-connected element.
  connectedCallback() {
    /**
	------------------------------------------------------------------------------------------------------------------------------------------
	SALESFORCE
	------------------------------------------------------------------------------------------------------------------------------------------
	*/
    // console.log(this.dataset.productionUrl);

    // if (this.validConfig) {
      crs.init();
      this.init();
    // } else {
    //   console.error("Invalid or missing Salesforce configuration");
    //   // this.style.display = "none";
    // }
  }

  // Invoked each time the custom element is disconnected from the document's DOM.
  disconnectedCallback() {}

  // Invoked each time the custom element is moved to a new document.
  adoptedCallback() {}

  // Invoked each time one of the custom element's attributes is added, removed, or changed.
  attributeChangedCallback() {}

  init() {
    this.setEnvironment();

    this.form = this.querySelector("#sf-form");
    this.btn = this.querySelector("#submit-lead");
    this.checkbox = this.querySelector(".checkbox");
    this.checkboxInput = this.querySelector(".checkbox-input");
    this.checkboxContainer = this.querySelector(".checkbox-container");

    this.country = document.getElementById("00Ni000000FlJ6K") as HTMLSelectElement;
    this.state = document.getElementById("00Ni000000FlJ6F") as HTMLSelectElement;

    const stateLabel = document.querySelector("#sf-form-state label") as HTMLElement;
    this.state.dataset['defaultOption'] = stateLabel.textContent;
    this.state.options[0].text = stateLabel.textContent;

    // Setup a listener to change whether State is required based on chosen Country
    if (HIDE_STATE_FOR_NON_US) {
      this.country.addEventListener("change", () => this.onCountryChange());
    }
    
    // Configure the phone input to adhere to our formatting
    this.setupPhoneInput();

    this.btn.addEventListener("click", () => this.onSubmit());

    this.handleCheckbox();

    if (this.isProduction) {
      this.configureForProduction();
    } else {
      this.configureForStaging();
    }

    this.loadReCaptchaScript();

    // Run intially to hide state field
    this.onCountryChange();
    this.filterRegions();


  }

  filterRegions() {

		setTimeout(function() { 
			var selectobject = document.getElementById("00Ni000000FlJ6F") as HTMLSelectElement;
			for (var i=0; i<selectobject.length; i++) {
				console.log(selectobject.options[i].value);
				if (selectobject.options[i].value == 'MH' || selectobject.options[i].value == 'PW' || selectobject.options[i].value == 'FM') {
					selectobject.remove(i);
				}
			}
		}, 500);
	}

  handleCheckbox() {
    this.checkboxContainer.addEventListener('click', (e) => {
      e.preventDefault();

      if(this.checkbox.ariaChecked == 'true') {
        // @ts-ignore
        this.checkbox.ariaChecked = false;
        this.checkboxInput.checked = false;
      } else {
        // @ts-ignore
        this.checkbox.ariaChecked = true;
        this.checkboxInput.checked = true;
      }
    })
  }

  setEnvironment() {
    const urls = this.dataset.productionUrl.split(",");
    const url = window.location.hostname;

    this.isProduction = urls.indexOf(url) !== -1;
  }

  loadReCaptchaScript() {
    window.captchaOnLoad = this.onReCaptchaScriptLoaded.bind(this);

    const url = "https://www.google.com/recaptcha/api.js";
    const queryString = "?onload=captchaOnLoad&render=explicit";
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url + queryString;
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
  }

  onReCaptchaScriptLoaded() {
    grecaptcha.render("captcha_container", {
      sitekey: this.captcha,
      callback: (response: any) => {
        DEBUG_VERBOSE && console.log(response);
        // return new Promise((resolve, reject) => {
        this.btn.disabled = false;
        // });
      },
    });
  }


  onSubmit() {
    var formData = new FormData(document.querySelector('form'))
    console.log(formData.entries())
    if (!this.form.checkValidity()) {
      this.form.reportValidity();
    } else {
      this.btn.removeEventListener("click", () => this.onSubmit());
      this.btn.classList.add("submitting");
      this.btn.innerHTML =
        '<svg width="28" height="18" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a"><stop stop-color="#fff" stop-opacity="0" offset="0%"/><stop stop-color="#fff" stop-opacity=".631" offset="63.146%"/><stop stop-color="#fff" offset="100%"/></linearGradient></defs><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)"><path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" /></path><circle fill="#fff" cx="36" cy="18" r="1"><animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" /></circle></g></g></svg>';
      this.hitcount++;
      if (this.hitcount == 1) {
        this.form.action = this.endpoint;
        this.form.submit();
      }
    }
  }

  setupPhoneInput() {
    // Setup the phone input
    this.querySelector("#phone").addEventListener("keypress", (e: any) => {
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (/^[0-9]+$/.test(str)) {
        var value = e.target.value;
        if ((value.length == 3 || value.length == 7) && value.length > !7) {
          e.target.value = value + "-";
        }
        if (value.length > 7) {
          value = value.split("");
          value[3] = "-";
          value[7] = "-";
          value = value.join("");
          e.target.value = value;
        }
        if (value && value.match(/-/g) && value.match(/-/g).length > 2) {
          e.target.value = "";
        }
        return true;
      }
      e.preventDefault();
      return false;
    });
  }

  configureForProduction() {
    this.endpoint = this.dataset.productionEndpoint
      .split("")
      .reverse()
      .join("");
    this.captcha = this.dataset.productionCaptcha;

    DEBUG_VERBOSE && console.log("Configuring for production");
    DEBUG_VERBOSE && console.log("Endpoint:", this.endpoint);
    DEBUG_VERBOSE && console.log("Captcha:", this.captcha);
  }

  configureForStaging() {
    this.endpoint = this.dataset.stagingEndpoint.split("").reverse().join("");
    this.captcha = this.dataset.stagingCaptcha;

    DEBUG_VERBOSE && console.log("Configuring for staging");
    DEBUG_VERBOSE && console.log("Endpoint:", this.endpoint);
    DEBUG_VERBOSE && console.log("Captcha:", this.captcha);
  }

  onCountryChange() {
    const stateEl = this.querySelector("#sf-form-state") as HTMLElement;
    const countryEl = this.querySelector("#sf-form-country") as HTMLElement;

    if (this.country.value !== "US") {
      this.state.value = "";
      this.state.required = false;
      stateEl.style.display = "none";
    } else {
      this.state.required = true;
      stateEl.removeAttribute("style");
      countryEl.removeAttribute("style");
    }
    this.filterRegions();
    countryEl.classList.toggle('lg:col-span-8', this.country.value !== "US");

  }

  get validConfig(): boolean {
    return (
      this.dataset.productionUrl !== "" &&
      this.dataset.productionEndpoint !== "" &&
      this.dataset.productionCaptcha !== "" &&
      this.dataset.stagingUrl !== "" &&
      this.dataset.stagingEndpoint !== "" &&
      this.dataset.stagingCaptcha !== ""
    );
  }
}

customElements.define(TAG_NAME, SalesforceBlock);
