import { gsap } from 'gsap';
import { Logger } from '../../utils/Logger';
import './CookieBanner.scss';

const DEBUG_VERBOSE = false;
const CLASS_NAME = '[CookieBannerBlock]';
const TAG_NAME = 'chunkwc-cookie-banner';

// ////////////////////////////////////////////////////////////////////

export default class CookieBannerBlock extends HTMLElement {
	private _el: CookieBannerBlock;
	private _button: HTMLElement;

	constructor() {
		super();
		DEBUG_VERBOSE && console.log(CLASS_NAME, 'constructed');
	}

	// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
	// Lifecycle Methods
	// https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_custom_elements#using_the_lifecycle_callbacks

	// Invoked each time the custom element is appended into a document-connected element.
	connectedCallback() {
		Logger.log(`${CLASS_NAME} Component connected`);

		// Bind the component to _el so that we can refer to it
		this._el = this;

		// Here we can put in any startup logic, in this case to hook up the button
		this._button = this.querySelector('button');
		if (this._button) this._button.addEventListener('click', this.onButtonClick.bind(this));

		// Check if we should display
		this.checkCookie();

	}

	onButtonClick(event: Event) {
		Logger.log(`${CLASS_NAME}`, event);
		this.setCookie();
	}

	checkCookie() {
		const cookie = document.cookie;
		if (cookie.indexOf('cookieconsent_status=allow') > -1) {
			this._el.remove();
		} else {
			this.showBanner();
		}
	}

	setCookie() {
		document.cookie = 'cookieconsent_status=allow';
		this.hideBanner();
	}

	showBanner() {
		const tl = gsap.timeline();
		tl.to(this._el, { duration: 0, autoAlpha: 1, ease: 'power2.out' });
	}

	hideBanner() {
		const tl = gsap.timeline();
		tl.to(this._el, { duration: 0.5, autoAlpha: 0, ease: 'power2.out' });
	}

	// Invoked each time the custom element is disconnected from the document's DOM.
	disconnectedCallback() {
		Logger.log(`${CLASS_NAME} Component disconnected`);
	}

	// Invoked each time the custom element is moved to a new document.
	adoptedCallback() {}

	// Invoked each time one of the custom element's attributes is added, removed, or changed.
	attributeChangedCallback() {}
}

customElements.define(TAG_NAME, CookieBannerBlock);
